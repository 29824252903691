import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  emailRegex,
  stringRegex,
  looseNorthAmericanPhoneRegex,
  canadianPostalCodeRegex,
} from "../../../helpers/regexes";
import { useEffect, useState } from "react";
import { campaignClient, getSessionClient, setSessionClient } from "../../../api";
import Loading from "../../../Components/Loading";

type RegisterFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  province: string;
  postal: string;
  rules: boolean;
  optin1: boolean;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };
export default function Registration() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormData>({ mode: "onTouched" });

  const [submitting, setSubmitting] = useState(false);

  async function handleRegister(data: RegisterFormData) {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    try {
      const { sessionKey } = await getSessionClient()
        .createParticipantBuilder()
        .setFirstName(data.firstName)
        .setLastName(data.lastName)
        .setEmail(data.email)
        .setPhone(data.phone)
        .setAddress(data.address)
        .setCity(data.city)
        .setProvince(data.province)
        .setPostal(data.postal)
        .setPreferredLanguage(i18n.language)
        .addMetadata({
          rules: data.rules,
          optin1: data.optin1,
        })
        .call<{ sessionKey?: string }>("register");

      if (sessionKey) {
        setSubmitting(false);
        setSessionClient(campaignClient.recoverSession(sessionKey));
        navigate("/upload");
      } else {
        window.vex.dialog.alert({
          unsafeMessage: t("errorMessages.somethingwent"),
          callback: () => {
            setSubmitting(false);
            navigate("/");
            return;
          },
        });
      }
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: t("errorMessages.somethingwent"),
        callback: () => {
          setSubmitting(false);
          navigate("/");
          return;
        },
      });
    }
    setSubmitting(false);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {submitting && <Loading />}
      <div className="section-head section-head--alt">
        <div style={{ textAlign: "center" }}>
          <h1 className="section__title">
            {t("register.freegift")}
            <span>{t("register.purchase")}</span>
          </h1> 
          {/* /.section__title */}
        </div>
      </div>
      <div className="form">
        <form onSubmit={handleSubmit(handleRegister)}>
          <div className="form__head">
            <h3 className="form__title">{t("register.signup")}</h3>
          </div>
          {/* /.form__head */}

          <div className="form__body">
            <div className="form__row">
              <label htmlFor="email" className="form__label">
                * {t("register.email")}
              </label>

              <div className="form__controls">
                <input
                  className="field"
                  {...register("email", {
                    required: { value: true, message: t("errorMessages.email") },
                    pattern: {
                      value: emailRegex,
                      message: t("errorMessages.emailValid"),
                    },
                  })}
                  placeholder={t("register.email").toString()}
                />
              </div>
              {/* /.form__controls */}
              {errors.email && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.email.message}
                </p>
              )}
            </div>
            {/* /.form__row */}

            <div className="form__row">
              <label htmlFor="firstname" className="form__label">
                * {t("register.firstName")}
              </label>

              <div className="form__controls">
                <input
                  className="field"
                  {...register("firstName", {
                    required: { value: true, message: t("errorMessages.firstName") },
                    pattern: {
                      value: stringRegex,
                      message: t("errorMessages.firstNameValid"),
                    },
                  })}
                  placeholder={t("register.firstname").toString()}
                />
              </div>
              {/* /.form__controls */}
              {errors.firstName && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.firstName.message}
                </p>
              )}
            </div>
            {/* /.form__row */}

            <div className="form__row">
              <label htmlFor="lastname" className="form__label">
                * {t("register.lastName")}
              </label>

              <div className="form__controls">
                <input
                  className="field"
                  {...register("lastName", {
                    required: { value: true, message: t("errorMessages.lastName") },
                    pattern: {
                      value: stringRegex,
                      message: t("errorMessages.lastNameValid"),
                    },
                  })}
                  placeholder={t("register.lastname").toString()}
                />
              </div>
              {/* /.form__controls */}
              {errors.lastName && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.lastName.message}
                </p>
              )}
            </div>
            {/* /.form__row */}

            <div className="form__row">
              <label htmlFor="phonenumber" className="form__label">
                * {t("register.phone")}
              </label>

              <div className="form__controls">
                <input
                  className="field"
                  {...register("phone", {
                    required: { value: true, message: t("errorMessages.phone") },
                    pattern: {
                      value: looseNorthAmericanPhoneRegex,
                      message: t("errorMessages.phoneValid"),
                    },
                  })}
                  placeholder={t("register.mobilenumber").toString()}
                />
              </div>
              {/* /.form__controls */}
              {errors.phone && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.phone.message}
                </p>
              )}
            </div>
            {/* /.form__row */}

            <div className="form__row">
              <label htmlFor="address" className="form__label">
                * {t("register.street")} {t("register.address")}
              </label>

              <div className="form__controls">
                <input
                  className="field"
                  {...register("address", {
                    required: { value: true, message: t("errorMessages.address") },
                  })}
                  placeholder={t("register.address").toString()}
                />
              </div>
              {/* /.form__controls */}
              {errors.address && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.address.message}
                </p>
              )}
            </div>
            {/* /.form__row */}

            <div className="form__row">
              <label htmlFor="city" className="form__label">
                * {t("register.city")}
              </label>

              <div className="form__controls">
                <input
                  id="city"
                  type="text"
                  className="field"
                  {...register("city", {
                    required: { value: true, message: t("errorMessages.city") },
                    pattern: {
                      value: stringRegex,
                      message: t("errorMessages.cityvalid"),
                    },
                  })}
                  placeholder={t("register.city").toString()}
                />{" "}
              </div>
              {/* /.form__controls */}
              {errors.city && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.city.message}
                </p>
              )}
            </div>
            {/* /.form__row */}

            <div className="form__row">
              <label htmlFor="province" className="form__label">
                * {t("register.province")}
              </label>

              <div className="form__controls">
                <div className="select">
                  <select
                    id="field-1#"
                    {...register("province", {
                      required: { value: true, message: t("errorMessages.province") },
                    })}>
                    <option value="">{t("errorMessages.province")}</option>
                    <option value="AB" key="AB">
                      {" "}
                      {t("provices.AB")}
                    </option>
                    <option value="BC" key="BC">
                      {" "}
                      {t("provices.BC")}
                    </option>
                    <option value="MB" key="MB">
                      {" "}
                      {t("provices.MB")}
                    </option>
                    <option value="NB" key="NB">
                      {" "}
                      {t("provices.NB")}
                    </option>
                    <option value="NL" key="NL">
                      {" "}
                      {t("provices.NL")}
                    </option>
                    <option value="NT" key="NT">
                      {" "}
                      {t("provices.NT")}
                    </option>
                    <option value="NS" key="NS">
                      {" "}
                      {t("provices.NS")}
                    </option>
                    <option value="NU" key="NU">
                      {" "}
                      {t("provices.NU")}
                    </option>
                    <option value="ON" key="ON">
                      {" "}
                      {t("provices.ON")}
                    </option>
                    <option value="PE" key="PE">
                      {" "}
                      {t("provices.PE")}
                    </option>
                    <option value="QC" key="QC">
                      {" "}
                      {t("provices.QC")}
                    </option>
                    <option value="SK" key="SK">
                      {" "}
                      {t("provices.SK")}
                    </option>
                    <option value="YT" key="YT">
                      {" "}
                      {t("provices.YT")}
                    </option>
                  </select>

                  <i className="ico-arrow select__icon">
                    <svg>{/* <use xlink:href="#ico-arrow"></use> */}</svg>
                  </i>
                </div>
                {/* /.select */}
              </div>
              {/* /.form__controls */}
              {errors.province && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.province.message}
                </p>
              )}
            </div>
            {/* /.form__row */}

            <div className="form__row">
              <label htmlFor="postalcode" className="form__label">
                * {t("register.postal")}
              </label>

              <div className="form__controls">
                <input
                  id="zip"
                  type="text"
                  className="field"
                  {...register("postal", {
                    required: { value: true, message: t("errorMessages.postal") },
                    pattern: {
                      value: canadianPostalCodeRegex,
                      message: t("errorMessages.postalValid"),
                    },
                  })}
                  placeholder={t("register.postalcode").toString()}
                />
              </div>
              {/* /.form__controls */}
              {errors.postal && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.postal.message}
                </p>
              )}
            </div>
            {/* /.form__row */}

            <div className="form__row">
              <span className="form__hint">{t("register.required")}</span>
            </div>
            {/* /.form__row */}

            <div className="form__row">
              <div className="checkbox">
                <input
                  id="rules"
                  type="checkbox"
                  {...register("rules", {
                    required: { value: true, message: t("errorMessages.rules") },
                  })}
                />

                <label htmlFor="rules">
                  * {t("register.agree")}{" "}
                  <Link
                    target="_blank"
                    to={i18n.language === "fr" ? "/rulesFr" : "/rulesEn"}
                    className="form__link">
                    {t("register.terms")}
                  </Link>{" "}
                  {t("register.terms2")}
                </label>
              </div>
              {/* /.checkbox */}
              {errors.rules && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.rules.message}
                </p>
              )}
            </div>
            {/* /.form__row */}

            <div className="form__row--offset">
              <div className="checkbox">
                <input id="optin1" type="checkbox" {...register("optin1", { required: false })} />

                <label htmlFor="optin1">
                  {t("register.privacy")}{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      i18n.language !== "fr"
                        ? "https://www.lego.com/en-ca/legal/notices-and-policies/privacy-policy"
                        : "https://www.lego.com/fr-ca/legal/notices-and-policies/privacy-policy"
                    }
                    className="form__link">
                    {t("register.policy")}
                  </a>
                  {t("register.details")}
                </label>
              </div>
              {/* /.checkbox */}
            </div>
            {/* /.form__row */}
          </div>
          {/* /.form__body */}

          <div className="form__actions">
            <button type="submit" className="btn">
              {t("register.now")}
            </button>
          </div>
          {/* /.form__actions */}
        </form>
      </div>
      {/* /.form */}
    </>
  );
}
