import { useEffect } from "react";

const RulesEn = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div style={{ padding: "5% 8% 10px" }}>
      <p style={{ textAlign: "center", color: "#FF0000" }}>
        <strong>LONG TERMS AND CONDITIONS:</strong>
      </p>
      <p>
        <strong>
          <u>
            Lego gift-with-purchase (the &ldquo;Promotion&rdquo;) Terms and Conditions (the
            &ldquo;Terms&rdquo;)
          </u>
        </strong>
      </p>
      <>
  <ol>
    <li>
      <strong>Eligibility:</strong> This Promotion is open only to Canadian
      residents who are the age of majority and older in their
      province/territory of residence.
    </li>
  </ol>
  <ol start={2}>
    <li>
      <strong>Offer:</strong> Spend $80 or more (before tax) on LEGO products at
      Walmart Canada location or online at Walmart.ca between January 24, 2024
      and February 16, 2024 inclusive <strong>(the “Promotion Period”)</strong>,
      and submit your receipt online to receive a gift consisting of one (1)
      LEGO® LUNAR NEW YEAR GIFT <strong>(the “Gift”)</strong>, while supplies
      last <strong>(the “Offer”).</strong>
    </li>
  </ol>
  <ol start={3}>
    <li>
      <strong>How to Participate:</strong> Scan the QR code available at
      participating Walmart locations in Canada or visit{" "}
      <strong>giftwithpurchase.ca</strong>. Next: (i) follow the on-screen
      instructions to complete the online form with all required information;
      (ii) upload a photo of the Walmart receipt that clearly shows the
      qualifying purchase; and (iii) click the check mark button to complete
      your registration <strong>(“Request”)</strong>. Purchase of the qualifying
      product must be made and upload must be completed by February 16, 2024 at
      11:59:59 p.m. Eastern Time. Accepted file formats are: .JPG, .JPEG, .PNG,
      and .GIF. Maximum file size is 5 MB. Message and data rates may apply.
      Participants should consult their wireless provider’s pricing plans. Once
      you submit a Request, and the Request is validated, you will receive the
      Gift by mail within six to eight weeks to the mailing address on the entry
      form.
    </li>
  </ol>
  <ol start={4}>
    <li>
      <strong>Offer Limits:</strong> There are a maximum of 3000 Gifts available
      throughout the Promotion Period. Offer only available while supply lasts.
      Limit one (1) Gift per household. Qualifying receipts may only be used one
      time during the Promotion Period (i.e. cannot be shared). Fraudulent or
      non-complying requests will not receive the Gift. LEGO System A/S, DK-7190
      Billund, Denmark (“Sponsor”) is not responsible for illegible, late,
      incomplete or non-complying requests.
    </li>
  </ol>
  <ol start={5}>
    <li>
      <strong>General Conditions:</strong> This Offer is subject to all
      applicable federal, provincial and municipal laws. The decisions of
      Sponsor with respect to all aspects of this Offer are final and binding on
      all participants without right of appeal. Sponsor, its parent company,
      subsidiaries, advertising/promotion agencies and their respective
      officers, directors, employees, representatives and agents{" "}
      <strong>(the “Released Parties”) </strong>will not be liable for: (i) any
      failure of any website or any platform during the Offer; (ii) any
      technical malfunction or other problems of any nature whatsoever,
      including, without limitation, those relating to the telephone network or
      lines, computer on-line systems, mobile devices, servers, access
      providers, computer equipment or software; (iii) the failure of any
      Request, and/or other information to be received, captured, recorded or
      work properly for any reason whatsoever, including, but not limited to,
      technical problems or traffic congestion on the internet or at any
      website; (iv) any injury or damage to a participant’s or any other
      person’s computer or other device related to or resulting from
      participating in the Promotion; (v) any lost, stolen, delayed, illegible,
      damaged, misdirected, late or destroyed Requests or Gifts; (vi) anyone
      being incorrectly and/or mistakenly identified as a recipient or eligible
      recipient; and/or (vii) any combination of the above. By participating in
      the Promotion, and as a condition of acceptance of the Offer, you, your
      heirs, executors, administrators, successors and assigns, release and
      forever discharge and hold harmless the Released Parties from any
      liability in connection with the Promotion, your participation therein and
      your acceptance, use or misuse of the Gift or any portion thereof. Sponsor
      reserves the right, in its sole and absolute discretion, to withdraw,
      amend or suspend this Promotion (or to amend these Terms) in any way, in
      the event of any cause beyond the reasonable control of Sponsor that
      interferes with the proper conduct of this Promotion as contemplated by
      these Terms, including, without limitation, any error, problem, computer
      virus, bugs, tampering, unauthorized intervention, fraud or failure of any
      kind whatsoever. Sponsor reserves the right, in its sole and absolute
      discretion, to cancel, amend or suspend this Promotion, or to amend these
      Terms, in any way without prior notice or obligation, in the event of any
      accident, printing, administrative, or other error of any kind, or for any
      other reason whatsoever. Without limiting the generality of the forgoing,
      each participant in the Promotion expressly consents to Sponsor, its
      agents and/or representatives, storing, sharing and using the personal
      information submitted only for the purpose of administering the Offer and
      in accordance with Sponsor’s privacy policy (available at:{" "}
      <strong>
        https://www.lego.com/en-ca/legal/notices-and-policies/privacy-policy/
      </strong>
      ). This section does not limit any other consent(s) that an individual may
      provide Sponsor or others in relation to the collection, use and/or
      disclosure of their personal information. The invalidity or
      unenforceability of any provision of these Terms shall not affect the
      validity or enforceability of any other provision. If any provision is
      determined to be invalid or otherwise unenforceable or illegal, these
      Terms shall otherwise remain in effect and shall be construed in
      accordance with the terms as if the invalid or illegal provision were not
      contained herein. To the fullest extent permissible by law, the parties
      waive all rights to trial in any action or proceeding instituted in
      connection with these Terms, including, without limitation, the Promotion.
      Any controversy or claim arising out of or relating to these Terms and/or
      the Promotion shall be settled by binding arbitration on an individual
      basis conducted in the Province of Ontario, City of Toronto, and shall not
      be consolidated in any arbitration with any claim or controversy of any
      other party.
    </li>
  </ol>
  <p>
    <strong>
      THESE TERMS AND THE INTERPRETATION OF ITS TERMS SHALL BE GOVERNED BY AND
      CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE PROVINCE OF ONTARIO AND THE
      LAWS OF CANADA APPLICABLE THEREIN WITHOUT REGARD TO ITS CONFLICTS OF LAWS
      RULES.
    </strong>
  </p>
</>

    </div>
  );
};

export default RulesEn;
