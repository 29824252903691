import React, { useEffect } from "react";

const RulesFr = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ padding: "5% 8% 10px" }}>
      <p style={{ textAlign: "center", color: "#FF0000" }}>
        <strong>VERSION LONGUE DES MODALIT&Eacute;S DU CONCOURS</strong>
      </p>
      <p>
        <strong>
          <u>
            Conditions d&rsquo;utilisation de la promotion &laquo;&nbsp;Cadeau Lego avec
            achat&nbsp;&raquo; (la &laquo;&nbsp;promotion&nbsp;&raquo;) (les &laquo;&nbsp;conditions
            d&rsquo;utilisation&nbsp;&raquo;)
          </u>
        </strong>
      </p>
      <>
        <ol>
          <li>
            <strong>Admissibilité&nbsp;:</strong> Cette promotion est ouverte uniquement aux
            résidents canadiens ayant atteint l’âge de la majorité dans leur province ou leur
            territoire de résidence.
          </li>
        </ol>
        <ol start={2}>
          <li>
            <strong>Offre&nbsp;:</strong> Dépensez 80&nbsp;$ ou plus (avant les taxes) sur produits
            LEGO dans un magasin Walmart au Canada ou en ligne sur Walmart.ca entre le 24 janvier et
            16 février&nbsp;2024 inclusivement{" "}
            <strong>(la «&nbsp;période de promotion&nbsp;»</strong>), et soumettez votre reçu en
            ligne pour recevoir un cadeau consistant en un&nbsp;(1)&nbsp;Cadeau LEGO® du Nouvel An
            lunaire <strong>(le «&nbsp;cadeau&nbsp;»</strong>), jusqu’à épuisement des stocks{" "}
            <strong>(l’«&nbsp;offre&nbsp;»).</strong>
          </li>
        </ol>
        <ol start={3}>
          <li>
            <strong>Comment participer&nbsp;:</strong> Numérisez le code&nbsp;QR de la promotion
            dans les magasins Walmart participants au Canada ou visitez{" "}
            <strong>giftwithpurchase.ca</strong>. Ensuite&nbsp;: (i)&nbsp;suivez les instructions
            affichées à l’écran pour remplir le formulaire en ligne; (ii)&nbsp;téléversez une photo
            du reçu Walmart qui montre clairement l’achat admissible; et (iii)&nbsp;cliquez sur le
            bouton de crochet pour terminer votre inscription{" "}
            <strong>(la «&nbsp;demande&nbsp;»</strong>). L’achat du produit admissible et le
            téléversement du reçu doivent être effectués avant le 16 février 2024 à
            23&nbsp;h&nbsp;59&nbsp;min&nbsp;59&nbsp;s, heure de l’Est. Les formats de fichiers
            acceptés sont&nbsp;: .JPG, .JPEG, .PNG et .GIF. La taille maximale des fichiers est de
            5&nbsp;Mo. Des frais de transmission de messages et de données peuvent s’appliquer. Dans
            ce cas, les participants devraient consulter les plans tarifaires de leur fournisseur de
            services sans fil. Une fois que vous avez soumis une demande et que celle-ci a été
            validée, vous recevrez le cadeau par courrier dans un délai de six à huit&nbsp;semaines
            à l’adresse postale indiquée sur le formulaire de participation.
          </li>
        </ol>
        <ol start={4}>
          <li>
            <strong>Limites de l’offre&nbsp;:</strong> Il y a un maximum de 3000&nbsp;cadeaux qui
            seront remis durant la période de la promotion. L’offre est valable jusqu’à épuisement
            des stocks. Limite d’un&nbsp;(1)&nbsp;cadeau par foyer. Les reçus admissibles ne peuvent
            être utilisés qu’une seule fois pendant la période de la promotion (c’est-à-dire qu’ils
            ne peuvent pas être partagés). Les participants qui soumettent des demandes frauduleuses
            ou non conformes ne recevront pas de cadeau. LEGO System A/S, DK-7190 Billund, Danemark
            (l’«&nbsp;organisateur&nbsp;») n’accepte aucune responsabilité à l’égard des demandes
            illisibles, en retard, incomplètes ou non conformes.
          </li>
        </ol>
        <ol start={5}>
          <li>
            <strong>Conditions générales&nbsp;:</strong> Cette promotion est assujettie à toutes les
            lois fédérales, provinciales et municipales applicables. Toutes les décisions de
            l’organisateur en ce qui concerne la promotion sont finales et sans appel.
            L’organisateur, sa société mère, ses filiales, les agences de publicité ou de promotion
            ainsi que leurs dirigeants, directeurs, employés, représentants et agents respectifs{" "}
            <strong>(les «&nbsp;renonciataires&nbsp;»)</strong> ne seront pas responsables de ce qui
            suit&nbsp;: (i)&nbsp;toute défaillance de sites Web ou de plateformes durant le
            concours; (ii)&nbsp;tout mauvais fonctionnement ou problème technique, y compris, mais
            sans s’y limiter, tout problème lié au réseau ou aux lignes téléphoniques, aux systèmes
            informatiques, aux appareils mobiles, aux serveurs, aux fournisseurs d’accès, à
            l’équipement informatique ou aux logiciels utilisés; (iii)&nbsp;tout problème empêchant
            la réception, l’enregistrement ou le traitement d’une demande et/ou de toute autre
            information pertinente, y compris, mais sans s’y limiter, les problèmes techniques ou la
            congestion sur Internet ou sur un site Web particulier; (iv)&nbsp;tout dommage causé à
            l’ordinateur ou à l’appareil du participant ou d’une autre personne à la suite de la
            participation au concours; (v)&nbsp;tout NIP, code promotionnel ou marchandise perdu,
            volé, retardé, illisible, endommagé, mal acheminé, en retard ou détruit; (vi)&nbsp;toute
            personne mentionnée de manière incorrecte ou par erreur comme étant un gagnant ou un
            gagnant potentiel; et/ou (vii)&nbsp;toute combinaison des facteurs ci-dessus. En
            participant à la promotion, et comme condition d’acceptation de l’offre, vous, vos
            héritiers, exécuteurs, administrateurs, successeurs et ayants droit, libérez et
            déchargez à jamais les renonciataires de toute responsabilité en rapport avec la
            promotion, votre participation à celle-ci et votre acceptation, utilisation (correcte ou
            incorrecte) de l’offre ou de toute partie de celle-ci. L’organisateur se réserve le
            droit, à sa seule discrétion, d’annuler, de retirer, de modifier ou de suspendre la
            promotion (ou d’en modifier les règlements) de toute manière, dans le cas d’un événement
            indépendant de sa volonté qui perturberait le déroulement adéquat du programme, tel que
            cela est décrit dans les présents règlements, y compris, mais sans s’y limiter, une
            erreur, un problème technique, un virus informatique, un bogue, une modification, une
            intervention non autorisée, une fraude ou une défaillance technique quelconque.
            L’organisateur se réserve le droit, à sa seule discrétion, d’annuler, de modifier ou de
            suspendre cette promotion, ou d’en modifier les règlements, sans avis ni obligation
            préalable, dans l’éventualité d’un accident, d’une erreur d’impression, administrative
            ou de toute autre erreur, ou pour toute autre raison. Sans que cela limite la portée de
            ce qui précède, chaque participant consent expressément à ce que l’organisateur, ses
            agents ou ses représentants stockent, partagent et utilisent les renseignements
            personnels soumis aux seules fins d’administration de l’offre et conformément à la
            politique de confidentialité de l’organisateur (accessible à l’adresse suivante&nbsp;:{" "}
            <strong>https://www.lego.com/fr-ca/legal/notices-and-policies/privacy-policy/</strong>
            ). Cette section ne limite aucun autre consentement qu’une personne pourrait accorder à
            l’organisateur ou à une autre entité concernant la collecte, l’utilisation et la
            divulgation de leurs renseignements personnels. Si une disposition des présents
            règlements s’avère non valide ou non applicable, les autres dispositions restent
            entièrement en vigueur. Advenant le cas où une disposition des présents règlements
            s’avère non valide, illégale, nulle ou inapplicable, le reste des règlements reste
            pleinement en vigueur, comme si la disposition en question ne s’y trouvait pas. Dans
            toute la mesure permise par la loi, les parties renoncent à tout droit à un procès dans
            toute action ou procédure engagée en rapport avec les présentes conditions, y compris,
            mais sans s’y limiter, la promotion. Toute controverse ou réclamation découlant des
            présentes conditions et/ou de la promotion, ou s’y rapportant, sera réglée par un
            arbitrage contraignant sur une base individuelle mené dans la province de l’Ontario,
            dans la ville de Toronto, et ne sera pas consolidée avec un autre arbitrage contenant
            une controverse ou une réclamation d’une autre partie.
          </li>
        </ol>
        <p>
          <strong>
            LES PRÉSENTES CONDITIONS ET LEUR INTERPRÉTATION SERONT RÉGIES ET INTERPRÉTÉES
            CONFORMÉMENT AUX LOIS DE LA PROVINCE DE L’ONTARIO ET AUX LOIS DU CANADA QUI Y SONT
            APPLICABLES, SANS ÉGARD À SES RÈGLES DE CONFLITS DE LOIS.
          </strong>
        </p>
      </>
    </div>
  );
};

export default RulesFr;
