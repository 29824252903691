import { Route } from "react-router-dom"
import Closed from "../Pages/Closed"
import ComingSoon from "../Pages/ComingSoon"
import Open from "../Pages/Open"
import Landing from "../Pages/Open/Landing"
import Registration from "../Pages/Open/Registration"
import Upload from "../Pages/Open/Upload"
import RedemptionOnly from "../Pages/RedemptionOnly"
import "../i18n"
import Questions from "../Pages/Open/Questions"
import Thankyou from "../Pages/Open/Thankyou"
import RulesEn from "../Pages/Rules/rulesEn"
import RulesFr from "../Pages/Rules/rulesFr"

export default (
  <>
    <Route path="/" element={<Open />}>
      <Route path="/" element={<Landing />} />
      <Route path="register" element={<Registration />} />
      <Route path="upload" element={<Upload />} />
      <Route path="questions" element={<Questions />} />
      <Route path="thankyou" element={<Thankyou />} />
    </Route>
    <Route path="rulesEn" element={<RulesEn />} />
    <Route path="rulesFr" element={<RulesFr />} />

    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<ComingSoon />} />
    <Route path="redemption" element={<RedemptionOnly />} />
  </>
)
