import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import disableBrowserHistory from "../../../Components/DisableBrowserBack";
import { ReactCropperElement } from "react-cropper";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { getSessionClient } from "../../../api";
import Loading from "../../../Components/Loading";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export default function Upload() {
  disableBrowserHistory();

  const { t } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState<any>();
  const [file, setFile] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        setImageLoaded(reader.result?.toString() || "");
      });

      setFile(e.target.files[0]);
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleUpload = async () => {
    if (!file || uploading) {
      return;
    }

    //if img > 5mb (5245329)

    if (file?.size > 5245330) {
      setUploading(false);

      window.vex.dialog.alert({
        unsafeMessage: t("confirm.maxSize"),
        callback: () => {
          setUploading(false);
          removeImage();
          return;
        },
      });

      return;
    }

    setUploading(true);
    try {
      const data = await file.arrayBuffer();

      const sessionClient = getSessionClient();
      const s3Key = await sessionClient.uploadFile({
        data,
        contentType: file.type,
      });
      const { isDuplicate, error, result, sessionKey } = await sessionClient.call<{
        isDuplicate: boolean;
        error?: "string";
        result?: any;
        sessionKey: string;
      }>("upload", {
        s3Key,
        filename: file.name,
        lastModified: new Date(),
      });

      if (isDuplicate) {
        setUploading(false);

        window.vex.dialog.alert({
          unsafeMessage: t("errorMessages.duplicate"),
          callback: () => {
            setUploading(false);
            removeImage();
            return;
          },
        });
        return;
      } else if (sessionKey) {
        setUploading(false);
        navigate("/questions");
      } else if (error) {
        window.vex.dialog.alert({
          unsafeMessage: t("errorMessages.somethingwent"),
          callback: () => {
            setUploading(false);
            removeImage();
            return;
          },
        });
      }
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: t("errorMessages.somethingwent"),
        callback: () => {
          setUploading(false);
          removeImage();
          return;
        },
      });
    }

    setUploading(false);
  };

  const removeImage = () => {
    setImageLoaded("");
    setFile(undefined);
  };

  return (
    <>
      {uploading && <Loading />}
      <div className="section-head section-head--alt">
        <div className="section__entry">
          {/* <h1 className="section__title">
            {t("register.freegift")}
            <span>{t("register.purchase")}</span>
          </h1> */}
          {/* /.section__title */}
        </div>
        {/* /.section__entry */}
      </div>
      {/* /.section-head section-head--alt */}

      {!imageLoaded ? (
        <div className="form-upload">
          <form>
            <div className="form__head">
              <h3 className="form__title">{t("upload.header")}</h3>
              {/* /.form__title */}
            </div>
            {/* /.form__head */}

            <div className="form__body">
              <label htmlFor="upload" className="form__label">
                <img width="150" height="150" src="/assets/images/svg/ico-camera.svg" alt="" />
              </label>

              <div className="form__controls">
                <input
                  type="file"
                  onChange={onSelectFile}
                  className="form__field"
                  accept="image/*, application/pdf"
                  id="upload"
                  name="upload"
                />
              </div>
              {/* /.form__controls */}
            </div>
            {/* /.form__body */}

            <div className="form__foot">
              <p>{t("upload.text1")}</p>

              <p>{t("upload.text2")}</p>
            </div>
            {/* /.form__foot */}
          </form>
        </div>
      ) : (
        <section className="section section--alt section--alt-offset">
          <header className="section__head">
            <h3 className="section__title">{t("confirm.header")}</h3>
            {/* /.section__title */}

            <p>{t("confirm.text1")}</p>
          </header>
          {/* /.section__head */}

          <div className="section__body">
            <div className="crop-container">
              <Cropper
                src={imageLoaded}
                // style={{
                //   height: "auto",
                //   width: "100%",
                //   maxWidth: "640px",
                // }}
                //initialAspectRatio={490 / 440}
                initialAspectRatio={490 / 440}
                zoomTo={0.5}
                guides={false}
                // viewMode={1}
                minCropBoxHeight={490}
                minCropBoxWidth={440}
                background={false}
                responsive={true}
                zoomable={false}
                zoomOnWheel={false}
                autoCropArea={1}
                checkOrientation={false}
                checkCrossOrigin={true}
                // onInitialized={(instance) => {
                //   setCropper(instance);
                // }}
                ref={cropperRef}
                // cropstart={onStart}
                // crop={onCrop}
              />
            </div>
          </div>
          {/* /.section__body */}

          <div className="section__controls">
            <button className="btn btn--large" onClick={handleUpload}>
              {t("confirm.btn1")}
            </button>

            <button className="btn btn--white btn--large" onClick={removeImage}>
              {t("confirm.upload")}
            </button>
          </div>
          {/* /.section__controls */}
        </section>
      )}
      {/* /.form-upload */}
    </>
  );
}
