import { useTranslation } from "react-i18next";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import "./index.css";
import { defaultStorageHelper } from "@spry/campaign-client";

export default function Landing() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();

  const userLang = defaultStorageHelper.get("lang");
  useEffect(() => {
    window.scrollTo(0, 0);
    !userLang && setOpenModal(true);
  }, [userLang]);
  const handleLanguageChange = (lan: string) => {
    setOpenModal(false);
    i18n.changeLanguage(lan);
    defaultStorageHelper.set("lang", lan);
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        contentLabel="My dialog"
        ariaHideApp={false}
        className="popup"
        portalClassName="modal"
        style={{
          overlay: {
            position: "fixed",
            zIndex: 9999,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.5)",

            //   -webkit-backface-visibility: hidden;
          },
        }}>
        <div className="loading loading--full-height">
          <div className="modal-language">
            <div className="modal__inner">
              <h3 className="modal__title">Select your language</h3>
              {/* /.modal__title */}

              <ul className="modal__list">
                <li>
                  <button
                    className="btn btn--full-width"
                    onClick={() => handleLanguageChange("en")}>
                    English
                  </button>
                </li>

                <li>
                  <button
                    className="btn btn--full-width"
                    onClick={() => handleLanguageChange("fr")}>
                    French
                  </button>
                </li>
              </ul>
              {/* /.modal__list */}
            </div>
            {/* /.modal__inner */}
          </div>
          {/* /.modal-language */}
        </div>
      </Modal>

      <div className="section-head">
        <div className="section__entry">
          <figure className="section__image" style={{ textAlign: "center", margin: "50px 0" }}>
            <img width="303" src="/assets/images/temp/gift.png" alt="" />
          </figure>
          {/* /.section__image */}

          <h1 className="section__title">
            {t("register.freegift")}
            <span style={{ textAlign: "center" }}>{t("register.purchase")}</span>
          </h1>
          {/* /.section__title */}
        </div>
        {/* /.section__entry */}

        {/* /.section__image */}
      </div>
      {/* /.section-head*/}

      <section className="section">
        <div className="section__content">
          <p>{t("landing.header1")}</p>

          <span>*{t("landing.supplies")}</span>

          <button
            style={{ maxWidth: "364px" }}
            onClick={() => {
              navigate("/register");
            }}
            className="btn">
            {t("landing.register")}
          </button>
        </div>
        {/* /.section__content */}
      </section>
      {/* /.section */}
    </>
  );
}
