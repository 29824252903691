import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import disableBrowserHistory from "../../../Components/DisableBrowserBack"

const Thankyou = () => {
  const { t} = useTranslation()
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  disableBrowserHistory()
  return (
    <>
      <div className="section-head section-head--small">
        <div className="section__entry">
          {/* <h1 className="section__title">
            {t("register.freegift")}
            <span style={{ textAlign: "center" }}>{t("register.purchase")}</span>
          </h1> */}
          {/* /.section__title */}
        </div>
        {/* /.section__entry */}
      </div>
      {/* /.section-head section-head-small */}

      <section className="section section--offset-bottom">
        <div className="section__content section__content--small">
          <p>{t("thankyou.text1")}</p>
        </div>
        {/* /.section__content */}
      </section>
    </>
  )
}

export default Thankyou
