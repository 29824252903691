import { useTranslation } from "react-i18next";
import { useRequiredCampaignState } from "../../helpers/campaignStates";

export default function Closed() {
  useRequiredCampaignState("closed");
  const { t } = useTranslation();
  return (
    <>
      <div className="section-head">
        <div className="section__entry">
          <h1 className="section__title">{t("closed.header")}</h1>
          {/* /.section__title */}
          <br />
          <div className="section__content">
            <p>{t("closed.text1")}</p>
          </div>
        </div>
        <figure className="section__image">
          <img width="343" height="360" src="/assets/images/temp/gift.png" alt="" />
        </figure>
        {/* /.section__entry */}
      </div>
    </>
  );
}
