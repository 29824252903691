import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import { defaultStorageHelper } from "@spry/campaign-client";

export default function Pages() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    defaultStorageHelper.set("lang", lang);
    if (location.pathname.includes("rules")) {
      navigate(`/rules${i18n.language}`);
    }
  };
  useEffect(() => {
    if (location.pathname.includes("rules")) {
      defaultStorageHelper.set("lang", location.pathname.includes("rulesEn") ? "en" : "fr");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (i18n.language === "en") {
      document.title = `LEGO Gift With Purchase`;
    } else {
      document.title = `Promotion de cadeau LEGO avec achat `;
    }
  }, [i18n.language]);

  return (
    <div className="wrapper">
      <div className="main">
        <header className="header-logo">
          <a href="#">
            <img
              width="100%"
              height="auto"
              src={
                i18n.language === "en"
                  ? "/assets/images/temp/desktop_header.png"
                  : "/assets/images/temp/desktop_header_fr.png"
              }
              alt="logo"
            />
          </a>
        </header>
        <div className="shell">
          <figure className="main__mobile-image">
            <a href="/">
              <img
                width="428"
                height="160"
                src={
                  i18n.language === "en"
                    ? "/assets/images/temp/mobile_header.png"
                    : "/assets/images/temp/mobile_header_fr.png"
                }
                alt=""
              />
            </a>
          </figure>

          {/* <figure className="main__background-left">
            <img
              width="300"
              height="1840"
              src="/assets/images/temp/background-left@2x.png"
              alt=""
            />
          </figure>
       
          <figure className="main__background-right">
            <img
              width="300"
              height="1840"
              src="/assets/images/temp/background-right@2x.png"
              alt=""
            />
          </figure> */}
          <Outlet />

          {/* /.main__mobile-image-bottom */}
        </div>
        {/* <figure className="main__desktop-image-bottom" style={{ textAlign: "center" }}>
          <img width="128" height="260" src="/assets/images/temp/lego_footer.png" alt="" />
        </figure> */}
        <picture>
          {/* Desktop use-case */}
          <source srcSet="/assets/images/temp/lego_footer.png" media="(min-width: 870px)" />
          {/* Mobile use case will be the default (could always swap them) */}
          <img src="/assets/images/temp/lego_footer-m.png" alt="Honda Img" className="headerImg" />
        </picture>
        <footer className="footer">
          <div className="shell">
            <div className="footer__inner">
              <nav className="nav-language">
                <ul>
                  <li>
                    <button
                      className={i18n.language === "en" ? "is-current header-btns" : "header-btns"}
                      onClick={() => handleLanguageChange("en")}
                      disabled={i18n.language === "en" ? true : false}>
                      EN
                    </button>
                  </li>

                  <li>
                    <button
                      className={i18n.language === "fr" ? "is-current header-btns" : "header-btns"}
                      onClick={() => handleLanguageChange("fr")}
                      disabled={i18n.language === "fr" ? true : false}>
                      FR
                    </button>
                  </li>
                </ul>
              </nav>
              {/* /.nav-language */}

              <nav className="footer__nav">
                <ul>
                  <li>
                    <a
                      href={i18n.language === "fr" ? "/rulesFr" : "/rulesEn"}
                      target="_blank"
                      rel="noreferrer">
                      {t("footer.terms")}
                    </a>
                  </li>

                  <li>
                    <a
                      href={
                        i18n.language !== "fr"
                          ? "https://www.lego.com/en-ca/legal/notices-and-policies/privacy-policy"
                          : "https://www.lego.com/fr-ca/legal/notices-and-policies/privacy-policy"
                      }
                      rel="noreferrer"
                      target="_blank">
                      {t("footer.privacy")}
                    </a>
                  </li>
                </ul>
              </nav>
              {/* /.nav */}

              <p className="copyright">{t("footer.copy")}</p>
              {/* /.copyright */}
            </div>
            {/* /.footer__inner */}
          </div>
          {/* /.shell */}
        </footer>
        {/* /.footer */}
      </div>
    </div>
  );
}
