import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getSessionClient } from "../../../api";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading";
import disableBrowserHistory from "../../../Components/DisableBrowserBack";

type formData = {
  question1: string[];
  question2: string[];
  otherAnswer1: string;
  otherAnswer2: string;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Questions = () => {
  const { t } = useTranslation();
  disableBrowserHistory();
  const [submitting, setSubmitting] = useState(false);
  const [q1, setQ1] = useState<formData>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<formData>({ mode: "onChange" });
  const otherAnswer = register("question1", {
    required: { value: true, message: t("errorMessages.q1") },
  });
  const otherAnswer2 = register("question2", {
    required: { value: true, message: t("errorMessages.q2") },
  });

  async function handlesubmitform(data: formData) {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    let answers1 = data.question1.includes("other")
      ? [...data.question1.filter((ans) => ans !== "other"), data.otherAnswer1]
      : data.question1;
    let answers2 = data.question2.includes("other")
      ? [...data.question2.filter((ans) => ans !== "other"), data.otherAnswer2]
      : data.question2;

    try {
      const { sessionKey } = await getSessionClient().call<{ sessionKey?: any }>("question", {
        q1: answers1,
        q2: answers2,
      });

      if (sessionKey) {
        navigate("/thankyou");
      } else {
        window.vex.dialog.alert({
          unsafeMessage: t("errorMessages.somethingwent"),
          callback: () => {
            setSubmitting(false);
            navigate("/questions");
            return;
          },
        });
      }
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: t("errorMessages.somethingwent"),
        callback: () => {
          setSubmitting(false);
          navigate("/questions");
          return;
        },
      });
    }
    setSubmitting(false);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //   console.log(getValues())
  return (
    <>
      {submitting && <Loading />}
      <div className="section-head section-head--alt section-head--alt-offset">
        <div className="section__entry">
          {/* <h1 className="section__title">
            {t("register.freegift")}
            <span>{t("register.purchase")}</span>
          </h1> */}
          {/* /.section__title */}
        </div>
        {/* /.section__entry */}
      </div>
      {/* /.section-head.section-head--alt */}
      <form onSubmit={handleSubmit(handlesubmitform)} className="form">
        <section className="section section--alt section--offset">
          <header className="section__head">
            <h3 className="section__title section__title--offset">{t("questions.header1")}</h3>
            {/* /.section__title */}
          </header>
          {/* /.section__head */}
          <div className="section__body">
            <div className="checkboxes">
              <p>{t("questions.q1")}</p>

              <ul>
                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-1"
                      type="checkbox"
                      value="lego_city"
                      {...register("question1", {
                        required: { value: true, message: t("errorMessages.q1") },
                      })}
                    />

                    <label htmlFor="option-1">{t("questions.option1")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>

                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-2"
                      type="checkbox"
                      value="lego_friends"
                      {...register("question1", {
                        required: { value: true, message: t("errorMessages.q1") },
                      })}
                    />
                    <label htmlFor="option-2">{t("questions.option2")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>

                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-3"
                      type="checkbox"
                      value="speed_hampions"
                      {...register("question1", {
                        required: { value: true, message: t("errorMessages.q1") },
                      })}
                    />
                    <label htmlFor="option-3">{t("questions.option5")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>

                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-4"
                      type="checkbox"
                      value="minicraft"
                      {...register("question1", {
                        required: { value: true, message: t("errorMessages.q1") },
                      })}
                    />
                    <label htmlFor="option-4">{t("questions.option4")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>
                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-11"
                      type="checkbox"
                      value="none"
                      {...register("question1", {
                        required: { value: true, message: t("errorMessages.q1") },
                      })}
                    />
                    <label htmlFor="option-11">{t("questions.none")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>

                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-5"
                      type="checkbox"
                      value="other"
                      {...otherAnswer}
                      onChange={async (e) => {
                        otherAnswer.onChange(e);
                        setQ1(getValues());
                        if (q1?.question1 && !q1?.question1.includes("other")) {
                          setValue("otherAnswer1", "", {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }
                        // console.log(getValues())
                      }}
                    />
                    <label htmlFor="option-5">{t("questions.other")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>

                <li>
                  {q1?.question1 && !!q1?.question1?.length && q1.question1?.includes("other") && (
                    <>
                      <textarea
                        {...register("otherAnswer1", {
                          required: {
                            value: true,
                            message: t("errorMessages.q1other"),
                          },
                        })}
                        className="text-area"
                      />

                      {errors.otherAnswer1 && (
                        <p className="error-message">
                          <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="Exclamation_Mark"/>
                          {errors.otherAnswer1.message}
                        </p>
                      )}
                    </>
                  )}
                </li>
              </ul>
              {errors.question1 && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="Exclamation_Mark"/>
                  {t("errorMessages.q1")}
                </p>
              )}
            </div>
            {/* /.checkboxes */}

            <div className="checkboxes">
              <p>{t("questions.q2")}</p>

              <ul>
                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-6"
                      type="checkbox"
                      value="lego_city"
                      {...register("question2", {
                        required: { value: true, message: t("errorMessages.q2") },
                      })}
                    />

                    <label htmlFor="option-6">{t("questions.option1")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>

                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-7"
                      type="checkbox"
                      value="lego_friends"
                      {...register("question2", {
                        required: { value: true, message: t("errorMessages.q2") },
                      })}
                    />
                    <label htmlFor="option-7">{t("questions.option2")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>

                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-8"
                      type="checkbox"
                      value="speed_champions"
                      {...register("question2", {
                        required: { value: true, message: t("errorMessages.q2") },
                      })}
                    />
                    <label htmlFor="option-8">{t("questions.option3")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>

                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-9"
                      type="checkbox"
                      value="minecraft"
                      {...register("question2", {
                        required: { value: true, message: t("errorMessages.q2") },
                      })}
                    />
                    <label htmlFor="option-9">{t("questions.option4")}</label>
                  </div>
                  {/* /.checkbox checkbox-/-alt */}
                </li>

                <li>
                  <div className="checkbox checkbox--alt">
                    <input
                      id="option-10"
                      type="checkbox"
                      value="other"
                      {...otherAnswer2}
                      onChange={async (e) => {
                        otherAnswer2.onChange(e);
                        setQ1(getValues());
                        if (q1?.question2 && !q1?.question2?.includes("other")) {
                          setValue("otherAnswer2", "", {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }
                      }}
                    />
                    <label htmlFor="option-10">{t("questions.other")}</label>
                  </div>
                </li>
                <li>
                  {q1 && q1?.question2 && q1.question2?.includes("other") && (
                    <>
                      <textarea
                        className="text-area"
                        {...register("otherAnswer2", {
                          required: { value: true, message: t("errorMessages.q1other") },
                        })}
                      />

                      {errors.otherAnswer2 && (
                        <p className="error-message">
                          <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="Exclamation_Mark"/>
                          {errors.otherAnswer2.message}
                        </p>
                      )}
                    </>
                  )}
                </li>
              </ul>

              {errors.question2 && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" alt="Exclamation_Mark"/>
                  {t("errorMessages.q2")}
                </p>
              )}
              <br />

              <span className="checkbox__hint">{t("questions.required")}</span>
            </div>
            {/* /.checkboxes */}
          </div>
          {/* /.section__body */}

          <div className="section__controls section__controls--alt">
            <button type="submit" className="btn">
              {t("register.now")}
            </button>
          </div>
          {/* /.section__controls */}
        </section>
        {/* /section section--/-alt */}
      </form>
    </>
  );
};

export default Questions;
