import { useTranslation } from "react-i18next";
import { useRequiredCampaignState } from "../../helpers/campaignStates";

export default function ComingSoon() {
  useRequiredCampaignState("comingsoon");
  const { t } = useTranslation();
  return (
    <>
      <div className="section-head section-head--largetitle">
        <div className="section__entry">
          <h1 className="section__title">{t("coming.comingsoon")}</h1>
          {/* /.section__title */}
        </div>
        {/* /.section__entry */}

        <img width="343" height="360" src="/assets/images/temp/gift.png" alt="" />

        {/* /.section__image */}
      </div>
    </>
  );
}
